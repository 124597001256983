/*
 * @Author: linjituan linjituan@revolution.com
 * @Date: 2024-02-29 18:34:47
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-04-24 11:53:17
 * @FilePath: \ra-web-admin\src\utils\baiduMap.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

// 天气值为以下时不提示警告
// const allowTextList = ['晴', '多云', '阴', '阵雨', '小雨', '中雨', '小到中雨', '雨', '中到大雨', '轻雾', '大雨']
export const getWeather = function (opts, timeResult) {
  // getWeather
  return new Promise((resolve, reject) => {
    const dataType = timeResult.in24h ? (timeResult.time === 0 ? 'now' : 'fc_hour') : 'fc' // now，fc，fc_hour
    return request({
      url: '/app/getWeather',
      method: 'get',
      params: {
        location: opts.location,
        dataType
      }
    }).then((res) => {
      console.log('getWeather', res)
      if (res.code === 200) {
        resolve(res.data)
      } else {
        reject(res.msg)
      }
    })
  })
}

export const checkWeather = function (opts, timestamp) {
  // vis 能见度(m) prec_1h 1小时降水量 wind_class 风力等级
  return new Promise(async (resolve, reject) => {
    const timeResult = checkTimestamp(timestamp)
    const weatherInfo = await getWeather(opts, timeResult)
    console.log('weatherInfo', weatherInfo)
    if (weatherInfo.status !== 0) {
      return reject(weatherInfo)
    }
    const now = weatherInfo.now || {}
    const forecasts = weatherInfo.forecasts || {}
    const forecastHours = weatherInfo.forecast_hours || {}
    if (timeResult.in24h) {
      if (timeResult.time === 0) {
        const warnResult = commonCheckWarnStatue(now.vis, now.prec_1h, now.wind_class)
        if (warnResult) {
          resolve(warnResult)
        }
      } else {
        const warnResult = commonCheckWarnStatue(
          null,
          forecastHours[timeResult.time].prec_1h,
          forecastHours[timeResult.time].wind_class
        )
        if (warnResult) {
          resolve(warnResult)
        }
      }
    } else {
      if (timeResult.time < 8) {
        const info = forecasts[timeResult.time - 1]
        const warnResult = commonCheckWarnStatue(info.vis, null, info.wc_day, info.prec)
        if (warnResult) {
          resolve(warnResult)
        }
      } else {
        resolve({
          type: 'warn',
          msg: '任务执行时间距离当前时间过长，天气预测可能误差较大，系统当前判断作业会有风险，是否确定执行作业？'
        })
      }
    }
    resolve({ type: 'success', msg: '无警告信息' })
  })
}

const commonCheckWarnStatue = function (_vis, prec1h, windClass, prec) {
  const obj = {
    isWarnVis: false,
    isWarnPrec: false,
    isWarnWindClass: false,
    commonTitle: '当前任务执行时天气预计',
    commonMsg: '系统当前判断作业会有风险，是否确定执行作业？'
  }
  let isWarn = false
  const vis = _vis * 1000
  if (_vis !== null && vis < 30) {
    // 能见度小于30 进行警告
    obj.isWarnVis = true
    isWarn = true
  }
  if (prec1h !== null && prec1h > 16) {
    // 1小时降水量大于16mm(雨强)，进行警告
    obj.isWarnPrec = true
    isWarn = true
  }
  if (prec !== null && prec > 50) {
    // 日降水量大于50mm(雨强)，进行警告
    obj.isWarnPrec = true
    isWarn = true
  }
  let theWindClass = windClass
  if (theWindClass && windClass.indexOf('~') > -1) {
    theWindClass = windClass.split('~')[1]
  }
  const windClassNum = theWindClass ? parseInt(theWindClass.replace(/级/g, '')) : null
  if (windClassNum !== null && windClassNum > 7) {
    // 台风大于7级，进行警告
    obj.isWarnWindClass = true
    isWarn = true
  }
  return isWarn ? obj : false
}

const checkTimestamp = function (timestamp) {
  // 检查时间戳
  // 判断时间戳距离当前时间多少小时
  const currentTimestamp = new Date().getTime()
  var timeDiff = Math.abs(currentTimestamp - timestamp)
  console.log('timeDiff', timeDiff)
  if (timeDiff < 1000 * 60 * 60 * 24) {
    // 时间戳小于24小时
    if (timeDiff < 1000 * 60 * 60) {
      // 时间戳小于1小时 使用now判断
      return {
        in24h: true,
        time: 0
      }
    } else {
      // 时间戳大于1小时 使用forecast_hours判断
      const t = Math.floor(timeDiff / 1000 / 60 / 60)
      return {
        in24h: true,
        time: t
      }
    }
  } else {
    // 时间戳大于24小时 forecasts 最多判断一周内天气
    const d = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
    return {
      in24h: false,
      time: d
    }
  }
}
