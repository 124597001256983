import request from '@/utils/request'

// 查询任务下发记录列表
export function listVehicleTaskRecord(query) {
  return request({
    url: '/iot/vehicleTaskRecord/list',
    method: 'get',
    params: query
  })
}

// 查询任务下发记录详细
export function getVehicleTaskRecord(id) {
  return request({
    url: '/iot/vehicleTaskRecord/' + id,
    method: 'get'
  })
}

// 新增任务下发记录
export function addVehicleTaskRecord(data) {
  return request({
    url: '/iot/vehicleTaskRecord',
    method: 'post',
    data: data
  })
}

// 修改任务下发记录
export function updateVehicleTaskRecord(data) {
  return request({
    url: '/iot/vehicleTaskRecord',
    method: 'put',
    data: data
  })
}

// 删除任务下发记录
export function delVehicleTaskRecord(id) {
  return request({
    url: '/iot/vehicleTaskRecord/' + id,
    method: 'delete'
  })
}

// 导出任务下发记录
export function exportVehicleTaskRecord(query) {
  return request({
    url: '/iot/vehicleTaskRecord/export',
    method: 'get',
    params: query
  })
}

// 暂停
export function pause(data) {
  return request({
    url: '/iot/vehicleTaskRecord/pause',
    method: 'post',
    data: data
  })
}

// 停止
export function stop(data) {
  return request({
    url: '/iot/vehicleTaskRecord/stop',
    method: 'post',
    data: data
  })
}

// 重新开始
export function resume(data) {
  return request({
    url: '/iot/vehicleTaskRecord/resume',
    method: 'post',
    data: data
  })
}

// 下发指令终止任务(多车监控)
export function stopVehilce(data) {
  return request({
    url: '/iot/vehicleTaskRecord/stopVehilce',
    method: 'post',
    data: data
  })
}

// 下发指令继续任务(多车监控)
export function resumeVehicle(data) {
  return request({
    url: '/iot/vehicleTaskRecord/resumeVehicle',
    method: 'post',
    data: data
  })
}

// 车端回馈中控开始任务指令ACK
export function operationAck(query) {
  return request({
    url: '/iot/vehicleTaskRecord/operationAck',
    method: 'get',
    params: query
  })
}

// 查询任务记录列表(自动作业管理)
export function listRecord(query) {
  return request({
    url: '/iot/vehicleTaskRecord/listRecord',
    method: 'get',
    params: query
  })
}
