import { pause, resume, stop, operationAck } from '@/api/iot/vehicleTaskRecord'
import { checkWeather } from '@/utils/baiduMap'

export default {
  data() {
    return {
      // 是否正在下发任务
      isSendingTask: false,
      // 下发任务标题
      sendTaskTitle: '',
      tips: 'loading',
      sendTaskMsg: '正在下发任务',
      spinning: false,
      createTaskTimeoutId: null,
      changeMapTimeoutId: null,
      taskStartTimeoutId: null,
      // 是否清理Timeout定时器
      isClearTimeout: false,
      // 调用接口获取状态计时
      getStatusStartTickcount: 0,
      // 获取任务状态超时设置(毫秒)
      getTaskStatusTimeout: 10 * 1000,
      getTaskAckTimeout: 10 * 1000,
      // 车端回传结果状态
      vehicleRemoteCtlResultOptions: []
    }
  },

  methods: {
    handleCloseSendTask() {
      this.clearTimeoutEx()
    },
    clearTimeoutEx() {
      console.log('clearTimeoutEx')
      this.taskAckTimeoutId && clearTimeout(this.taskAckTimeoutId)
    },
    // 获取任务Ack
    // ack方法名，ack参数及任务操作key
    getTaskAck(ackMethod, ackParams, taskAction, record) {
      if (this.isClearTimeout) {
        // 退出定时器任务
        console.log('退出定时器任务-getTaskAck')
        return
      }
      const actionName = this.getActionName(taskAction)
      const loopFlag = this.getLoopAckFlag(taskAction)
      this.taskAckTimeoutId = setTimeout(() => {
        // 调用获取状态接口
        // this.tips = '正在获取' + actionName + '状态，请稍候。。。'
        this.$loading.show({
          msg: '正在获取' + actionName + '状态，请稍候。。。'
        })
        ackMethod(ackParams)
          .then((response) => {
            const data = response.data
            const taskResult = this.getTaskAckResultValue(data, taskAction)
            let result = ''
            let infoType = ''
            if (taskResult === 888 || (loopFlag && taskResult === loopFlag)) {
              const curDateTime = new Date()
              const tickcount = curDateTime.getTime() - this.taskAckBeginTickount.getTime()
              if (tickcount <= this.getTaskAckTimeout) {
                this.getTaskAck(ackMethod, ackParams, taskAction, record)
              } else {
                result = actionName + '超时'
                infoType = 'warn'
                this.toEnd(infoType, result)
              }
            } else {
              this.isClearTimeout = true
              if (taskResult === 0) {
                result = actionName + '成功'
                infoType = 'success'
              } else {
                infoType = 'warn'
                result = this.getTaskAckResultRemark(taskResult, taskAction)
              }
              this.toEnd(infoType, result, taskResult, record)
            }
          })
          .catch((e) => {
            this.$loading.hide()
          })
      }, 3000)
    },
    // 获取当前操作任务名称
    getActionName(taskAction) {
      let actionName
      if (taskAction === 'start') {
        actionName = '执行任务'
      } else if (taskAction === 'pause') {
        actionName = '暂停任务'
      } else if (taskAction === 'stop') {
        actionName = '停止任务'
      } else if (taskAction === 'resume') {
        actionName = '重新启动任务'
      } else if (taskAction === 'mapDowlodStart') {
        actionName = '下载地图'
      } else if (taskAction === 'mapChange') {
        actionName = '切换地图'
      } else if (taskAction === 'mapInt') {
        actionName = '暂停下载地图'
      } else if (taskAction === 'mapDel') {
        actionName = '删除地图'
      } else if (taskAction === 'mapSync') {
        actionName = '同步车端地图'
      } else if (taskAction === 'createTask') {
        actionName = '车端创建任务'
      } else if (taskAction === 'syncTask') {
        actionName = '同步车端任务'
      } else if (taskAction === 'autoReset') {
        actionName = '设置返回固定点任务'
      } else if (taskAction === 'cancelAutoReset') {
        actionName = '取消返回固定点任务设置'
      } else if (taskAction === 'delTask') {
        actionName = '删除任务模板'
      } else if (taskAction === 'autoResetGet') {
        actionName = '同步车端返回固定点任务设置'
      }
      return actionName
    },
    // 获取当前任务操作Ack Loop的标识
    // 目前都是888
    getLoopAckFlag(taskAction) {
      let flag = 888
      if (
        [
          'start',
          'mapDowlodStart',
          'mapChange',
          'mapInt',
          'mapDel',
          'mapSync',
          'createTask',
          'syncTask',
          'autoReset',
          'cancelAutoReset',
          'delTask'
        ].indexOf(taskAction) >= 0
      ) {
        flag = 888
      } else if (taskAction === 'pause') {
        flag = 881
      } else if (taskAction === 'resume') {
        flag = 882
      } else if (taskAction === 'stop') {
        flag = 883
      }
      return flag
    },
    // 获取任务Ack操作结果
    // 目前都是data.result
    getTaskAckResultValue(data, taskAction) {
      let result
      if (
        [
          'pause',
          'stop',
          'resume',
          'start',
          'createTask',
          'syncTask',
          'autoReset',
          'cancelAutoReset',
          'delTask'
        ].indexOf(taskAction) >= 0
      ) {
        result = data.result
      } else if (['mapDowlodStart', 'mapChange', 'mapInt', 'mapDel', 'mapSync'].indexOf(taskAction) >= 0) {
        result = data.result
      } else {
        result = data.result
      }
      return result
    },
    // 获取任务Ack操作结果描述
    getTaskAckResultRemark(taskResult, taskAction) {
      return this.selectDictLabel(this.vehicleRemoteCtlResultOptions, taskResult)
    },
    // 暂停任务
    // 操作任务记录表
    handlePauseTask(id, record) {
      this.$loading.show({
        msg: '正在暂停任务'
      })
      const formData = new FormData()
      formData.append('id', id)
      pause(formData)
        .then((response) => {
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(operationAck, params, 'pause', record)
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    // 停止任务
    // 操作任务记录表
    handleStopTask(id, record) {
      this.$loading.show({
        msg: '正在停止任务'
      })
      const formData = new FormData()
      formData.append('id', id)
      stop(formData)
        .then((response) => {
          // 获取执行任务状态
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(operationAck, params, 'stop', record)
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    // 重新启动任务
    // 操作任务记录表
    handleResumeTask(id, record) {
      this.$loading.show({
        msg: '正在重新启动任务'
      })
      const formData = new FormData()
      formData.append('id', id)
      resume(formData)
        .then((response) => {
          // 获取执行任务状态
          this.taskIsRunning = true
          this.isClearTimeout = false
          this.taskAckBeginTickount = new Date()
          const params = {
            vehicleId: this.vehicleId
          }
          this.getTaskAck(operationAck, params, 'resume', record)
        })
        .catch((e) => {
          console.log(e)
          this.$loading.hide()
        })
    },
    checkWeatherEvent(timestamp) {
      // 天气预警判断
      return new Promise((resolve, reject) => {
        checkWeather(
          {
            location: this.info.location.longitude + ',' + this.info.location.latitude
          },
          timestamp
        ).then((res) => {
          if (res.type === 'success') {
            resolve('Ok')
            return
          }
          if (res.type === 'warn') {
            this.$confirm({
              title: '警告',
              content: (h) => <div style="color:red;">{res.msg}</div>,
              onOk() {
                console.log('OK')
                resolve('Ok')
              },
              onCancel() {
                console.log('Cancel')
                resolve('onCancel')
              },
              class: 'test',
              zIndex: 100000
            })
            return
          }
          this.$confirm({
            title: (h) => (
              <div>
                <div>当前任务执行时天气预计</div>
                {res.isWarnVis ? <div>能见度过低</div> : ''}
                {res.isWarnPrec ? <div>降雨量过大</div> : ''}
                {res.isWarnWindClass ? <div>风力等级过大</div> : ''}
              </div>
            ),
            content: (h) => <div style="color:red;">系统当前判断作业会有风险，是否确定执行作业？</div>,
            onOk() {
              console.log('OK')
              resolve('Ok')
            },
            onCancel() {
              console.log('Cancel')
              resolve('onCancel')
            },
            class: 'test',
            zIndex: 100000
          })
        })
      })
    },
    beforeSubmitHandle(record, type) {
      return new Promise(async (resolve, reject) => {
        if ((record.schedulerType === 0 && type === 'isSumbit') || type === 'isOpen') {
          const timestamp = record.schedulerType === 0 ? new Date(record.startTime).getTime() : new Date().getTime()
          const result = await this.checkWeatherEvent(timestamp)
          if (result === 'Ok') {
            resolve()
          } else {
            reject(result)
          }
        } else {
          resolve()
        }
      })
    }
  }
}
